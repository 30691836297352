import React, { FC } from 'react';
import clsx from 'clsx';
import useResponsiveProps from '../../../hooks/use-responsive-props';
import './text-lockup.scss';

export type TextAlignmentOption = 'left' | 'center' | 'right';

export type TextAlignment = TextAlignmentOption | TextAlignmentOption[];

export interface TextLockupContentProps {
  alignment?: TextAlignment;
  eyebrow?: string;
  headline: string;
  description?: string;
  primaryCtaText?: string;
  primaryCtaUrl?: any;
  secondaryCtaText?: string;
  secondaryCtaUrl?: any;
}

export interface TextLockupProps extends React.HTMLAttributes<HTMLDivElement> {
  alignment?: TextAlignment;
  fullWidth?: boolean;
}

export const TextLockup: FC<TextLockupProps> = ({
  children,
  alignment = 'left',
  fullWidth,
  className
}) => {
  const { getPropV2 } = useResponsiveProps();
  const classNames = clsx(
    'text-dark-green text-lockup-container',
    getPropV2(alignment, 'alignment'),
    fullWidth && 'w-full',
    className
  );

  return <div className={classNames}>{children}</div>;
};
